import React, { useContext } from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';

import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import { ImportantInformation } from '@latitude/important-information';
import { Link } from '@latitude/link/Link';
import { Metadata } from '@latitude/metadata';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import { FeatureTiles } from '@latitude/feature-tiles';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Responsive } from '@latitude/spacing';
import { FeaturesSlider } from '@latitude/features-slider';
import {
  ALIGN,
  ALIGN_ITEMS,
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  JUSTIFY_CONTENT,
  PADDING,
  MARGIN
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import { BudgetPlanner } from '@latitude/budget-planner';

import Layout from '@/components/layout';
import { Card } from '@/components/Card/CardNew';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';
import { ICON_VARIANT } from '../../utils/constants';
import PageData from './budget-planner.json';
import heroImage from '../../images/hero-budget-planner.webp';
import { SOFT_QUOTE_LOANS_URL } from '../../utils/softQuoteUtil';
import { PageContext } from '../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const BudgetPlannerPage = ({ location }) => {
  const featureSliderItems = [
    {
      icon: 'icon-snap-fingers-branded',
      title: 'Un-complicated loans',
      text:
        'Get an indicative Quote in 2 minutes, Apply in under 7 minutes. Once approved and you accept your contract, funds in your account in less than 24 hours.'
    },
    {
      icon: 'icon-slinky',
      title: 'Flexible options',
      text:
        'At Gem, you can choose to apply for a Fixed or Variable loan, along with an option to set up monthly, fortnightly or weekly repayments.'
    },
    {
      icon: 'icon-extras-branded',
      title: 'Redraw extra funds',
      text:
        "Get flexible access to funds when you need them, when you're ahead on repayments with a Variable Rate Personal Loan."
    }
  ];

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [
    {
      heading: 'Why choose a Gem Loan?',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Metadata
            title={`${PageData.title}`}
            description={PageData.description}
            canonical={location.pathname}
          />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title="Budget Planner"
              subTitle="Get a better view of your finances with our easy to use budget planner."
            />
          )}
          <div
            css={`
              .sticky-navigation--fixed {
                position: fixed;
              }
            `}
          >
            <StickyNavigation
              items={PageData.nav}
              isSticky
              offsetElem="[data-sticky-navigation-offset]"
              {...state?.inPageNavData?.[0]}
            />
          </div>
          <ConfettiFramedContainer id="planner" hasPlainBgColor="transparent">
            <Box
              css={`
                padding: ${PADDING.P8};
                @media (min-width: ${BREAKPOINT.MD}) {
                  padding: 56px;
                }
              `}
            >
              <Heading4
                color={COLOR.BLACK}
                align={ALIGN.CENTER}
                css={`
                  margin-bottom: ${PADDING.P16};
                  margin-top: ${PADDING.P24};
                  @media (min-width: ${BREAKPOINT.MD}) {
                    margin-top: 0;
                  }
                `}
              >
                Budget Planner
              </Heading4>
              <Text
                align={ALIGN.CENTER}
                css={`
                  padding: 0 ${PADDING.P16};
                  max-width: 540px;
                  margin: 0 auto ${PADDING.P32};
                `}
              >
                Use our handy planner to work out where you’re spending, so you
                can save for what you really want!{' '}
              </Text>
              <div id="repayment-calculator">
                <AnalyticsLocationProvider location="Budget Planner">
                  <BudgetPlanner />
                </AnalyticsLocationProvider>
              </div>
            </Box>
          </ConfettiFramedContainer>
          <HorizontalRule />

          {featureSliderData[0] && (
            <FeaturesSlider
              backgroundColor={COLOR.GREY6}
              heading={featureSliderData[0].heading}
              subheading={featureSliderData[0].description}
              data={featureSliderData[0].featureCards}
              className="why-choose pt-4 pt-md-5"
            />
          )}
          <HowToApplySection id={PageData.nav[1].anchor} />
          <FeatureTiles
            id={PageData.nav[2].anchor}
            heading={PageData.content.calcTools.title}
            tiles={PageData.content.calcTools.tiles}
          />
          <ImportantInformation
            data={require('../../data/pages/calculators-and-tools/personal-loan-repayment-calculator.json')}
            sectionOneColummClasses="col-10 offset-1"
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
};

export const HowToApplySection = props => {
  const title = 'How to apply';
  // const applyCarLoanUrl = redirectUrl(LOAN_PURPOSE.AUTO_PURCHASE);

  return (
    <AnalyticsLocationProvider location={title}>
      <Box.Section
        id={props.id}
        paddingTop={PADDING.P24}
        paddingBottom={PADDING.P32}
        backgroundColor={COLOR.BLUE_BABY}
      >
        <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
          {title}
        </Heading4>
        <Responsive
          alignItems={ALIGN_ITEMS.CENTER}
          justifyContent={JUSTIFY_CONTENT.CENTER}
          spacing={MARGIN.M56}
          breakpoint={BREAKPOINT.LG}
        >
          <HowToApplyCard
            icon={ICON_VARIANT.APPLY_ONLINE_BRAND}
            heading="Apply online"
          >
            <Box marginBottom={MARGIN.M16}>
              <Text align={ALIGN.CENTER}>
                Check your interest rate before applying for a Personal Loan or
                Car Loan today.
              </Text>
            </Box>
            <Link
              button={BUTTON_STYLE.PRIMARY}
              href={SOFT_QUOTE_LOANS_URL}
              trackId="how-to-apply-get-my-personal-loan-rate"
              trackEventData={{
                category: 'cta',
                action: 'quote-link'
              }}
              trackProductId={['PLNZLF-WEB']}
              css={`
                margin-bottom: ${MARGIN.M16};
              `}
              target="_self"
            >
              Get rate estimate
            </Link>
          </HowToApplyCard>
          <HowToApplyCard icon={ICON_VARIANT.CALL_US_BRAND} heading="Call us">
            <Box marginBottom={MARGIN.M16}>
              <Text align={ALIGN.CENTER}>
                Call our team
                <br />
                Mon - Fri: 9:00am - 6:30pm (NZST)
              </Text>
            </Box>
            <Link
              button={BUTTON_STYLE.TERTIARY}
              href="tel:0800 422 898"
              trackId="why-latitude--how-to-apply--call"
              css={`
                margin-bottom: ${MARGIN.M16};
              `}
            >
              0800 422 898 for Personal Loans
            </Link>
          </HowToApplyCard>
        </Responsive>
      </Box.Section>
    </AnalyticsLocationProvider>
  );
};

const HowToApplyCard = styled(Card)`
  flex: 1 1 auto; // fixes IE11 align issue
  max-width: 280px;
  > div:first-child {
    width: 128px;
    height: 128px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  svg {
    stroke: inherit;
    width: auto;
    height: auto;
  }
  h5 {
    color: ${COLOR.BLACK};
  }
`;

export default BudgetPlannerPage;
