// @flow

import * as React from 'react';
import styled from 'styled-components';
// import Icon from '@latitude/icon';
// import Icon from '@/components/Icon/Icon';
import Icon from '../Icon/Icon';
import {
  ALIGN,
  COLOR,
  ICON_SIZE,
  ICON_VARIANT,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import { Box, Flex } from '@latitude/box';
import { Heading5 } from '@latitude/heading';

type Props = {
  /** Set the background-color. [$Values<typeof COLOR>] */
  backgroundColor?: $Values<typeof COLOR>,
  /** Set the min-height. */
  minHeight?: number | string,
  /** Set the card heading. */
  heading?: string,
  /** Set the icon variant if applicable. */
  icon?: $Values<typeof ICON_VARIANT>,
  /** The child elements of the component. */
  children?: React.Node
};

const StyledCard = styled(Box)`
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  ${({ backgroundColor }: Props) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  ${({ minHeight }: Props) => (minHeight ? `min-height: ${minHeight};` : '')};
`;

export const Card = ({ heading, icon, ...props }: Props) => {
  return (
    <StyledCard {...props}>
      {icon && (
        <Flex justifyContent={JUSTIFY_CONTENT.CENTER} marginBottom={MARGIN.M16}>
          <Icon variant={icon} size={ICON_SIZE.LG} color={COLOR.BLUE} />
        </Flex>
      )}
      {heading && (
        <Heading5
          marginBottom={MARGIN.M16}
          align={ALIGN.CENTER}
          color={COLOR.BLUE}
        >
          {heading}
        </Heading5>
      )}
      <Box>{props.children}</Box>
    </StyledCard>
  );
};

Card.Box = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }: Props) =>
    backgroundColor ? backgroundColor : COLOR.GREY5};
  > h4,
  > h5 {
    margin: ${MARGIN.M0};
    padding: ${PADDING.P16};
    background-color: ${COLOR.BLUE};
    color: ${COLOR.WHITE};
  }
  > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: ${PADDING.P24} ${PADDING.P32};
  }
`;

Card.List = styled(Card)`
  max-width: 360px;
`;

export default Card;
